<template>
  <div>
    <b-row class="mb-4 sticky-filters">
      <b-col>
        <DashboardFilter
          :filters="filters"
          :widgets="widgets"
          @dateFilterChange="updateDateFilter"
          @compareFilterChange="updateCompDateFilter"
          @refresh="refresh()"
          @reset="resetFilters()"
          @saveFilters="saveFilters"
        ></DashboardFilter>
      </b-col>
    </b-row>
    <div v-if="contracttype_id !== 10 || (contracttype_id === 10 && user.isAdmin) || (contracttype_id === 10 && user.isBookkeeper)">
      <div v-if="lastsync" style="color: white; font-size:medium; text-align: center;"><small>Last Synced: {{ lastsync }}</small></div>
      <b-row>
        <b-col>
          <DashboardLayout
            :widgets="widgets"
            :layout="layout"
            :filters="filters"
            @dashboardReportSetDisplay="reportSetDisplay"
            @refresh="refresh()"
          ></DashboardLayout>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <b-row>
        <b-card title="Dashboard Restricted.">
          <b-card-text>This is an example dashboard that presents charts and graphs displaying your pertinent financial information in a quick visual display,
            giving you an informative snapshot of your businesses key performance indicators.
            The dashboard is a premium feature for the Entrepreneur Package.
            If you are interested in learning more about the dashboard and how you can get it, please contact <a style="color: #BE191E;" href="mailto:sales@streamlinedbookkeeping.com">sales@streamlinedbookkeeping.com</a></b-card-text>
          <b-card-img :src="'/img/logo/Dash_Example.png'" alt="Image" bottom></b-card-img>
        </b-card>
      </b-row>
    </div>
  </div>
</template>
<script>
import DashboardLayoutService from '@/services/DashboardLayoutService'
import { bus } from '@/main'

import DashboardFilter from '@/components/dashboard/Filter'
import DashboardLayout from '@/components/dashboard/Layout'
import UserService from '@/services/UserService'
import StorageService from '@/services/StorageService'
import moment from 'moment'
import AdminService from '@/services/AdminService'

export default {
  data () {
    return {
      loading: false,
      user: null,

      widgets: [
        { name: 'Revenue By Source', report: 'RevenueByAgent', display: ['graph', 'table'], width: 1, height: '60vh', details: 'Depicts Revenue/GCI closed by Source per the time frame selected' },
        { name: 'Operating Expense', report: 'OperatingExpense', display: ['graph', 'table'], width: 1, height: '60vh', details: 'Operating Expense Category as compared to Total/Overall Operating Expenses for the given time period.' },
        { name: 'Lead Generation', report: 'LeadGeneration', display: ['graph', 'table'], width: 1, height: '60vh', details: 'Depicts the individual cost by Vendor in order from Most Spent to Least Spent over the time frame selected' },
        { name: 'Revenue Comparison', report: 'RevenueComparison', display: ['graph'], width: 3, height: '60vh', details: 'Provides insight to the current Revenue Closed compared to the time frame selected as a comparison' },
        { name: 'P&L Review', report: 'TotalExpense', display: ['graph', 'table'], width: 2, height: '60vh', details: 'Cost of Sale and Operating Expenses stacked against Revenue' },
        { name: 'Home Inspection', report: 'HomeInspection', display: ['graph', 'table'], width: 2, height: '60vh', details: 'Cost of Sale and Operating Expenses stacked against Revenue for Home Inspection' },

        { name: 'Net Income', report: 'NetIncomeKPM', display: ['table'], width: 1, details: 'Total Revenue as specified in the timeframe. Available Revenue Comparison for same time period.' },
        { name: 'Taxable Income', report: 'TaxableIncomeKPM', display: ['table'], width: 1, details: 'Utilizing current tax tables, the Net Income of THIS BUSINESS ONLY is utilized as an estimated annual Federal amount owed' },
        { name: 'Hourly Rate', report: 'HourlyRateKPM', display: ['table'], width: 1, details: 'Calculates the Net Income of THIS BUSINESS ONLY divided by the standard hours worked over the time frame selected' },
        { name: 'Break Even', report: 'BreakEvenKPM', display: ['table'], width: 1, details: 'Calculated Total Operating Expenses divided by the number of Months selected for a time frame' },
        { name: 'Gross Sales', report: 'GrossSalesKPM', display: ['table'], width: 1, details: 'Net Profit as specified in the timeframe. Available Profit Comparison for same time period.' }
      ],

      layout: null,

      filtersDefault: {
        company_id: null,
        period: 'month',
        type: 'all',
        display: 'percent',
        startDate: null,
        endDate: null,
        comparestart: null,
        compareend: null
      },
      lastsync: null,
      contracttype_id: null,

      filters: {}
    }
  },

  mounted () {
    this.getFilters()
    this.getLayout()
    this.user = StorageService.get('user')
    bus.$on('getWidgets', () => { bus.$emit('widgets', this.widgets) })
  },

  methods: {

    reportSetDisplay (data) {
      if (this.layout) {
        this.layout[data.index].display = data.display
        DashboardLayoutService.save({ layout: this.layout })
      }
    },

    getFilters () {
      DashboardLayoutService.getFilters().then(
        async (response) => {
          const filters = response.data.info
          if (filters && filters !== null) {
            this.filters = filters
          } else {
            this.resetFilters()
          }
          if (!this.filters.company_id) {
            let companies = null
            if (UserService.isAdmin()) {
              companies = await AdminService.getCompanies()
            } else {
              companies = await UserService.getCompanies()
            }
            if (companies.length > 0) {
              this.filters.company_id = companies[0].id
            }
          }
        }
      )
    },

    getLayout () {
      DashboardLayoutService.getLayout(this.filters).then(
        (response) => {
          this.layout = response.data.info
        }
      )
    },

    updateDateFilter (data) {
      this.filters.startDate = moment(data.startDate).format('YYYY-MM-DD')
      this.filters.endDate = moment(data.endDate).format('YYYY-MM-DD')
    },

    updateCompDateFilter (data) {
      if (data.startDate && data.endDate) {
        this.filters.comparestart = moment(data.startDate).format('YYYY-MM-DD')
        this.filters.compareend = moment(data.endDate).format('YYYY-MM-DD')
      } else {
        this.filters.comparestart = null
        this.filters.compareend = null
      }
    },

    async resetFilters () {
      // console.dir(this.filters)
      let companies = null
      if (UserService.isAdmin()) {
        companies = await AdminService.getCompanies()
      } else {
        companies = await UserService.getCompanies()
      }
      if (companies.length > 0) {
        this.filtersDefault.company_id = companies[0].id
      }
      this.filters = JSON.parse(JSON.stringify(this.filtersDefault))
    },

    saveFilters (filters) {
      DashboardLayoutService.save({ filters: filters })
    },

    refresh () {
      this.getFilters()
      this.getLayout()
    }
  },
  watch: {
    filters: {
      handler: function (n, o) {
        if (n) {
          this.getLayout()
          DashboardLayoutService.save({ filters: n }).then((value) => {
            if (value.data.info.lastsync) {
              this.lastsync = value.data.info.lastsync
            }
            if (value.data.info.contracttype_id) {
              this.contracttype_id = value.data.info.contracttype_id
            } else {
              this.contracttype_id = null
            }
            // if not a home inspection company remove the home inspection widget.
            if (value.data.info.industry !== 12) {
              this.widgets.splice(5, 1)
              this.layout.forEach(w => {
                if (w.report === 'HomeInspection') {
                  this.layout.splice(w, 1)
                }
              })
            }
            // checking if we switch back to a home inspection company and refresh the page to reload the layout
            if (value.data.info.industry === 12) {
              if (this.widgets.length < 11) {
                this.$router.go(0)
              }
            }
          })
        }
      },
      deep: true
    }
  },
  components: {
    DashboardFilter,
    DashboardLayout
  }
}
</script>

<style scoped>
.sticky-filters {
  display: block;
  width: 100%;
  position: sticky;
  top: 0;
  left: 15px;
  z-index: 100;
}
</style>
